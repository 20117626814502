import React from 'react'
import { Helmet } from 'react-helmet'
import './all.sass'
import './global.css'
import useSiteMetadata from './SiteMetadata'
import CookieConsent from "react-cookie-consent";
import Footer from '../components/Footer'

const TemplateWrapper = ({ children }) => {
  const { title, image, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/favicon/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={image} />

        <meta name="format-detection" content="telephone=no"/>
        <meta name="format-detection" content="email=no"/>
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700&subset=latin,latin-ext&display=swap" rel="stylesheet"></link>

      </Helmet>
      <div className="Wrapper">
        <div className="Page">
          <div className="Page-body">
            <main
              style={{
                flexGrow: 1
              }}>
              {children}
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateWrapper
