import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="section is-fluid">
          <div className="container">
            <h2 className="has-text-white" style={{fontSize: '1.4rem',marginBottom: '16px', fontWeight: 'bold'}}>Kontakt</h2>
            <div className="columns">
              <div className="column is-one-third">
                <ul className="has-text-white">
                  <li>Forretningsfører: Tor Haver</li>
                  <li>Kontaktperson: Tone Finnesand</li>
                  <li>Adresse: c/o Haver Advokatfirma AS</li>
                  <li>Haakon VIIs gate 8, 4005 Stavanger</li>
                </ul>
              </div>
              <div className="column">
                <ul className="has-text-white">
                  <li>Telefon: 51 55 44 10</li>
                  <li>E-post: post@haver.no</li>
                  <li>Organisasjonsnummer: 977 290 171</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column" style={{marginTop: '25px', textAlign: 'center', color: 'white', fontSize: '12px'}}>
              <span>© Stavanger, All Rights Reserved // Designet av: </span>
              <a className="footer-copyright-link" href="https://www.siljedesign.com">Siljedesign.com</a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
